*{
    margin: 0;
    padding: 0;
}
.footer{    
    background-color: #F6F8FF;
    display: flex;
    justify-content:center;
    align-items: center;
    height: 150px;
    align-items: flex-end;
}
#icons{
    height: 45px;
    width: 45px;
    margin: 10px;
    margin-left: 40px;
    margin-right: 40px;
    padding: 5px;
    transition: all .3s ease-in-out ;
    opacity: .5;
}

#icons:hover{
    transform: scale(1.2);
    color: aliceblue;
    opacity: 1;
}

@media screen and (max-width: 500px) {
    #icons{
        height: 35px;
        width: 35px;
        margin: 20px;
    }
    
}