@import url("https://fonts.googleapis.com/css2?family=Ubuntu&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
* {
  margin: 0;
  box-sizing: border-box;
  padding: 0;
  font-family: "Ubuntu", sans-serif;
}
#navbar {
  background: #f6f8ff;
  width: 100%;
  height: 90px;
  position: sticky;
  top: 0;
}
.alignMe{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
#navbarCon{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80%;
  justify-content: space-between;
  

}

#name {
  font-family: Ubuntu;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: linear-gradient(180deg, #3c579b 0%, #283556 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

#header-item {
  margin: 0 1rem 0 3rem;
  color: #464646;
  font-family: Roboto;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  display: flex;
}
#header-item:hover {
  transform: scale(1.07);
  color: #3c579b;
}
#items {
  display: flex;
}

#header {
  width: 100%;
  /* position: sticky; */
  top: 0;
  z-index: 2;
}

@media screen and (max-width: 780px) {
  #header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #header-item {
    margin: 0 1rem 0 1rem;
  }
  #name {
    font-size: 3rem;
  }
  #navbar {
    height: 5rem;
  }
}

@media screen and (max-width: 590px) {
  #header-item {
    margin: 0 1rem 0 1rem;
    font-size: 1rem;
  }
  #name {
    font-size: 2rem;
  }
}
@media screen and (max-width: 400px) {
  #header-item {
    margin: 0 0.5rem 0 0.5rem;
    font-size: 0.8rem;
  }
  #name {
    font-size: 1.8rem;
  }
}
