* {
  padding: 0;
  margin: 0;
}
body {
  background-color: #ffffff !important;
  padding-top: 0px;
  margin-top: 0px;
}

#header {
  margin: 0;
  padding: 0;
}

#mainContainer {
  display: flex;
  width: 80%;
  height: 680px;
  align-items: center;
}
.container{
  max-width: 80% !important;
}
#textCon {
  display: flex;
  flex-direction: column;
  width: 70%;
}

#bottom {
  display: flex;
  margin-top: 20px;
}
.aboutImageContainer{
  display: flex;
  /* position: relative; */
  width: 50%;
  
}
#imageBox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 497px;
  width: 497px;
  border-radius: 597px;
  animation: gradientAnimation 5s linear infinite alternate;
  background: radial-gradient(circle, rgba(224,233,255,1) 100%, rgba(71,105,191,1) 100%);
}

#image {
  width: 430px;
  height: 430px;
}
#text {
  color: #3e454b;
  font-family: Ubuntu;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.move {
  top: 10px;
}
.bottomContainer {
  display: flex;
  align-items: center;
}
#textBottom {
  display: flex;
  color: #000;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 183.5%
}

@media screen and (max-width: 1600px) {
  #mainContainer {
  }

  #image {
    height: 400px;
    width: 400px;
  }
  #imageBox{
    height: 467px;
    width: 467px;
  }
  .move{
    font-size: 38px !important;
    margin-top: 0 !important;
  }
  #text {
    margin-left: 0rem;
    margin-top: 4rem;
    /* width: 55rem; */
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 130%;
  }
  #textBottom {
    font-size: 20px;
    margin-left: 0rem;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    width: auto;
    line-height: 183.5%;
  }
}
@media screen and (max-width:1300px) {
  .aboutImageContainer{
    width: auto;
  }
  #image {
    height: 380px;
    width: 380px;
  }
  #textCon{
    width: 90%;
    align-items: center;
  }
  #imageBox{
    height: 447px;
    width: 447px;
  }
  #mainContainer{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #textBottom{
    width: 800px;
  }
  
}
@media screen and (max-width: 1000px) {
  #text {
    margin-left: 0rem;
    margin-top: 4rem;
    /* width: 55rem; */
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 130%;
  }
  .move{
    font-size: 40px !important;
    top: 6px ;
  }
  #image {
    height: 350px;
    width: 350px;
  }
  #imageBox{
    height: 417px;
    width: 417px;
  }
  #textBottom {
    font-size: 22px;
    margin-left: 0rem;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    width: auto;
    line-height: 183.5%;
  }
}
@media screen and (max-width: 700px) {
  #mainContainer {

    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #image {
    height: 310px;
    width: 310px;
  }
  .topBox{
    margin-top:30px !important;
  }
  #imageBox{
    height: 377px;
    width: 377px;
  }
  #text {
    margin-left: 0rem;
    margin-top: 4rem;
    font-family: "Ubuntu";
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 130%;
  }
  .move{
    font-size: 26px !important;
  }
  #textBottom {
    font-size: 16px;
    margin-left: 0rem;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    line-height: 183.5%;
  }

}
@media screen and (max-width: 500px) {
  #mainContainer {

  }
  #image {
    height: 240px;
    width: 240px;
  }
  #imageBox{
    height: 290px;
    width: 290px;
  }
  #text {

    font-size: 20px;

  }
  #textBottom {
    font-size: 13px;
    margin-left: 0rem;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    line-height: 183.5%;
  }
  .move{
    font-size: 20px !important;
    top: 5px !important; ;
  }
}
  

