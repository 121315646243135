#contactCon {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  overflow: hidden;
  height: 700px;
}
#contact {
  width: 100%;
}
.contactBox {
  width: 80%;
  display: flex;
  position: relative;
  justify-content: center;
  overflow: hidden;
  border-radius: 29px;
  height: 100%;
  z-index: 2;
  background: linear-gradient(91deg, #3c579b 34.36%, #132f76 66.85%);
}
#texture {
  position: absolute;
  right: 0;
  z-index: -1; /* This places the image underneath everything */
  /* Adjust the width and height of the image as needed */
height: 800px;
width: 662px;
  /* Set the background image and any other styles for your texture */
  background-image: url("./assets/texture.png");
  background-repeat: no-repeat;
  background-size: contain;
}
#contactImg {
  display: flex;
  height: 25rem;
  margin: 80px 80px 60px 80px;
}
#contactImgStyle {
  display: flex;
  justify-content: center;
}
#contactTextBox a{
  color: #fff;

}
#contactTextBox {
  color: #fff;
  font-family: Roboto;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: 183.5%;
  margin-bottom: 0px;
}
#contactTitle {
  color: #FFF;
  font-family: Ubuntu;
  font-size: 46px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}
.formContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
}
#topInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 20px;
}
.smallInput {
  width: 48%;
}
.form-control {
  border-radius: 0 !important;
  height: 65px;
  font-size: 20px !important;
}
#messageInput {
  resize: none;
  height: 120px !important;
}
.form-label {
  color: #fff;
  font-family: Ubuntu;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}
#contactContainer {
  display: flex;
  margin-top: 4rem;
}
#contactSubTitle {
  display: flex;
  width: 50%;
  display: inline-block;
}
#form {
  line-height: 0.5rem;
}
.formButton {
  margin-top: 1.7rem;
  border-radius: 8px;
  background: #708fe0;
  border-radius: 8px;
}
.btn-primary {
  background-color: #708fe0 !important;
  width: 200px;
  height: 48px;
}

@media screen and (max-width: 1800px) {
  #sphere1 {
    z-index: -1;
  }
}
@media screen and (max-width: 1200px) {
  #contactTitle{
    font-size: 40px;
  }  
  #contactTextBox {
    font-size: 26px;
    line-height: 150%;
  }

}

@media screen and (max-width: 992px) {
  #contactContainer {
    justify-content: center;
    margin-top: 30px;
  }

}

@media screen and (max-width: 768px) {

  #contactContainer {
    display: flex;
    justify-content: center;
    margin-top: 10;
  }
  #contactSubTitle {
    display: flex;
    width: 50%;
    display: inline-block;
  }
  #contactTitle {
    font-size: 30px;
  }
  #contactTextBox {
    font-size: 20px;
    line-height: 150%;
  }
  .form-label{
    font-size: 20px;
  }


}
@media screen and (max-width: 600px) {
  #topInfo {
    flex-direction: column;
  }
  .smallInput {
    width: 100%;
    margin-top: 15px;
  }
  .form-control{
    height: 45px;
  }
  .contactBox{
    justify-content: flex-end;
  }
  #contactCon{
    height: 770px;
  }
  .formContainer{
    width: 100%;}
  #form{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
  }
  #contactTextBox{
    display: flex;
    flex-direction: column;
    width: 80%;
    font-size: 18px ;
  }

}
