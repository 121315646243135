.breakerOne {
    background: linear-gradient(91deg, #3C579B 34.36%, #132F76 66.85%);
    width: 100%;
    height: 184px;
    margin: 1rem 0 5rem 0;
    display: flex;

    flex-direction: row;
    flex-wrap: nowrap;
    overflow: hidden;
}
@keyframes scrollLeft {
    0% {
      transform: translateX(0%); /* Start off-screen to the right */
    }
    100% {
      transform: translateX(-100%); /* Move off-screen to the left */
    }
  }

.iconBox{
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    width: 11% ;
}
.iconImg{
    height: 120px;
    width: 85px;
}
#breakerBoxOneTwo{
    white-space: nowrap;
    overflow: hidden;
    animation: scrollLeft 35s linear infinite;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    height: 100%;
    flex: 0 0 150%;
    width: 100%;

}
#breakBoxOne{
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    animation: scrollLeft 35s linear infinite;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;

    height: 100%;
    flex: 0 0 150%;
}

@media screen and (max-width: 1050px){
    .iconImg{
        height: 100px;
        width: 75px;
    }
    

    
}
@media screen and (max-width: 800px){
    .iconImg{
        height: 80px;
        width: 55px;
    }
    .breakerOne{
        height: 130px;
    }
    
}
@media screen and (max-width: 700px){

    
}
@media screen and (max-width: 523px){

}
@media screen and (max-width: 500px){
    .iconImg{
        height: 50px;
        width: 35px;
    }
    .breakerOne{
        height: 100px;
    }


    
}
