#resumeCon {
  display: flex;
  justify-content: center;
}
#resumePic {
  display: flex;
  height: 25rem;
  margin: 80px 80px 60px 80px;
}
#resumePicStyle {
  display: flex;
  justify-content: center;
}
#resumeTextBox {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 183.5%;
  justify-content: center;
}
#resumeTitle {
  display: flex;
  justify-content: center;
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 150%;
}
#resumeContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 4.5rem;
  margin-right: 1rem;
}
/* .btn-primary{
    background-color: #3c579b !important;
} */

@media screen and (max-width: 768px) {
  #resumeCon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  #resumePic{
    height: 20rem;
    margin: 50px;
  }
  #resumeContainer{
    margin: 0px;
    
  }
  
}
