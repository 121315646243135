* {
  margin: 0;
  padding: 0;
}
.card{
  background-color: #F6F8FF !important;
}
#portDiv {
  /* margin-top: 2rem; */
  background-color: #F6F8FF;
  width: 80%;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
}
/* portCard styling */
.portPage {
  justify-content: center;
  display: flex;
}
.card {
  align-items: center;
  margin: 5px;
}
#click{
  width: 18rem;
  height: 18rem;
  position: absolute;
  transform: translate(0, 5rem);
}
#portCard {
  background-color: #ffffff;
}
#title {
  margin-top: 15px;
  margin-bottom: 0px;
  line-height: 2.5rem;
  font-size: 1.3rem;
  width: 75%;
  color: #464646;
  font-weight: 700;
  font-style: normal;
  transition: all .2s ease-in-out ;
}
#desc {
  font-size: 1rem;
  color: #aaaaaa;
  transition: all .2s ease-in-out ;
}
#lang {
    line-height: 1px;
  font-size: 0.8rem;
  width: 20rem;
  color: #aaaaaa;
  transition: all .2s ease-in-out ;
}
#textBox {
      width: 480px;
  flex-wrap: wrap;
  justify-content: center;
}
#portImg {
  display: flex;
  width: 30rem;
  border-radius:  15px;
  transition: all .2s ease-in-out ;
}
.allTogether{
  margin-bottom: 30px;
  margin-top: 30px;
  transition: all .2s ease-in-out ;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#portImg:hover #desc{
  color: #464646;

}
.allTogether:hover #desc{
  color: #464646;
}
.allTogether:hover #lang{
  color: #464646;
}
.allTogether:hover #title{
  color: #464646;
}
.allTogether:hover{
  scale: 1.03;
}



#titleBox{
  width: 100%;
  display: flex;
  justify-content: space-between;
  
}
#linkBox{
  display: flex;
  flex-direction: row;
  margin-top: 15px;

}
.icons{
  display: flex;
  align-items: center;
  z-index: 1;
  transition: all .1s ease-in-out;
}

.icons:hover{
  transform: scale(1.05);
}

@media screen and (max-width:1290px) {
  #portDiv {
    width: 100%;
  }
  
}
@media  screen and (max-width:1050px) {
  #portDiv{
    flex-direction: column;
  }
  
}
@media screen and (max-width: 480px) {
  #portImg {
    width: 100%;
    height: 100%;
  }
  .fullCard{
    width: 100% !important;
  }
  #portDiv {
    width: 90%;
  }
  #textBox {
    width: 90%;
  }
  #title{
    font-size: 18px;
  }
  #titleBox{
    width: 100%;
  }
  #desc{
    width: 100%;
    font-size: 16px;
  }

  
}
